import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ButtonCreationEntity } from '@core/models/ButtonCreationEntity';
import { ButtonActionEntity } from '@core/models/ButtonActionEntity';
import { LinkedEntity } from '@core/models/LinkedEntity';

@Injectable({
  providedIn: 'root',
})
export class DetailActionService {
  creationSubject: BehaviorSubject<ButtonCreationEntity | undefined> = new BehaviorSubject<
    ButtonCreationEntity | undefined
  >(undefined);
  actionMenuItemsSubject: BehaviorSubject<ButtonActionEntity[] | undefined> = new BehaviorSubject<
    ButtonActionEntity[] | undefined
  >(undefined);

  linkedSubject: BehaviorSubject<LinkedEntity | undefined> = new BehaviorSubject<LinkedEntity | undefined>(undefined);

  getCreationSubject(): ButtonCreationEntity | undefined {
    return this.creationSubject.value;
  }

  setLinkedSubject(linkedEntity?: LinkedEntity) {
    this.linkedSubject.next(linkedEntity);
  }

  setCreationSubject(subj?: ButtonCreationEntity) {
    this.creationSubject.next(subj);
  }

  setActionMenuItemsSubject(items?: ButtonActionEntity[]) {
    this.actionMenuItemsSubject.next(items);
  }
}
